import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import './BarMenu.css'




const BtnAdd = ({ shoppingcart }) => {
  const navigate = useNavigate();
  const { business_code, branchoffice_code, machine_code_table } = useParams();

  let businessCode = business_code;
  let branchofficeCode = branchoffice_code;
  let machineCodeTable = machine_code_table;


  let countProduct = 0
  if (shoppingcart != undefined) {

    shoppingcart.forEach((product) => {
      countProduct = (countProduct + parseInt(product.amount))
    });

  }


  return (
    <>

      <NavLink
        to={`/menu/${businessCode}/${branchofficeCode}/${machineCodeTable}`}
      >
        <button className='btn btn-primary btn-sm w-100 '>Agregar más productos</button>
      </NavLink>



    </>
  )
}

export default BtnAdd

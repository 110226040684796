import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import ErrorAlert from '../Alerts/ErrorAlert';
const LoginStand = ({ modalStand, setModalStand, tableNow, background, handleSaveBranchoffice, branchoffices }) => {
   let logo = localStorage.getItem('logo')
   const [passwordRoom, setPasswordRoom] = useState();

   const handleSave = () => {if(branchoffices.length > 0){handleSaveBranchoffice(branchoffices[0])}else{setModalStand(false)}}
   const authcodeRoom = () =>{
      let password = tableNow.value.password
      let input = document.getElementById('passwordRoom')
      if(password == passwordRoom){
         localStorage.setItem('codeRoom', passwordRoom)
         localStorage.setItem('table',  JSON.stringify(tableNow.value))
         ErrorAlert('', 'Codigo verificado con exito', 'success')
         handleSave()
      }else{
         input.value=""
         ErrorAlert('', 'Codigo incorrecto', 'error')
      }
   }
   return (
      <>
         <Modal
            show={modalStand}
            onHide={() => setModalStand(false)}
            fullscreen
            aria-labelledby="contained-modal-title-vcenter"
            centered         >

            <Modal.Body>

               <section className='ecomerce-container-0' style={{ backgroundImage: `url(${background})` }}>

                  <header className='text-center fw-bold d-flex justify-content-center  align-items-center shadow-sm'>
                     <div>
                        <img src={logo}
                           alt="Logo Bussiness" className='img-ecommerce-header rounded-3' />
                     </div>
                  </header>


                  <div class="position-absolute top-50 start-50 translate-middle ">

                     <div className="bg-light border-sp d-flex align-items-center justify-content-center  p-3">

                        <div className='mt-5'>
                           <h2 className='text-center'>Bienvenido a la habitacion: </h2>
                           <h1 className='text-center txtRoom mt-3'>{tableNow.label}</h1>
                        </div>
                     </div>

                     <div className=" text-dark  rounded-4 position-relative text-wrap bg-white   p-2">
                        <div className='d-flex'>
                           <Form>

                              <div className="mb-3 kiosk-text-card">
                                 <span className="fw-bold">Ingresa el codigo de la habitacion</span>
                                 <input id="passwordRoom" type="password" className="form-control border rounded-1 w-100" placeholder="**********" onChange={(e) => setPasswordRoom(e.target.value)}
                                 />
                              </div>
                           </Form>
                        </div>
                        <button className={`btn btn-primary rounded btn-sm kiosk-subtitle-md position-relative px-4 w-100`} onClick={() => authcodeRoom()}
                        >
                           Ingresar
                        </button>
                     </div>


                  </div>


               </section>
               {/*<div className='position-absolute '>
                  <div className='fw-bold badge rounded-2 text-bg-primary'>
                     <i class="uil uil-shop"></i>  Stan
                  </div>
               </div>*/}


            </Modal.Body>
            {/* <Modal.Footer>
               <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
         </Modal>
      </>
   )
}

export default LoginStand
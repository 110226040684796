import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
//Component
import { BarMenu } from '../common/BarMenu/BarMenu'
import { Header } from '../common/Header/Header'
//css

import './branch.css'

import ModalLoginUserClient from '../../layouts/Kiosk_ecommers_stant/ModalLoginUserClient';
import ModalDetailUser from '../../layouts/Kiosk_ecommers_stant/ModalDetailuser';
import ErrorAlert from '../Alerts/ErrorAlert';
import ModalWelcomePage from '../../layouts/Kiosk_ecommers_stant/ModalWelcomePage';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { BusinessFetchAPI } from '../../api/Business';
import { AuthFetchAPI } from '../../api/Auth';
import { MachinesFetchAPI } from '../../api/Machines';
import { ClientsFetchAPI } from '../../api/Clients';
import { TablesFetchAPI } from '../../api/Tables';
import { ParthnerFetchAPI } from '../../api/Parthner';
import LoginStand from '../Profile/LoginStand';
//Icons
import { MenuBar } from '../../layouts/Kiosk_ecommers_stant/components/MenuBar/MenuBar';

function BranchofficeSelect() {
    const { business_code, tableCode, type } = useParams();
    const [showModalWelcomePage, setShowModalWelcomePage] = useState(true)
    const [modalStand, setModalStand] = useState(false)
    const [branchoffices, setBranchoffices] = useState([])
    const [background, setBackground] = useState('')
    const [businessinfo, setBusinessinfo] = useState({ id: 0 })
    const [showModal, setShowModal] = useState(false);
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [dataClient, setDataClient] = useState({ client_name: 'Soy usuario', client_id: '0', client_ruc: "0000-00", client_ruc: "00" })
    const [dataTable, setDataTable] = useState({ name: 'Soy un stan' })
    const [pageLoader, setPageLoader] = useState(0)
    const [clients, setClients] = useState([])
    const [tables, setTables] = useState([]);
    const [typeUser, setTypeUser] = useState('')
    const [token, setToken] = useState('')
    const [parthners, setParthners] = useState([])
    const [logo, setLogo] = useState('')
    const [tableNow, setTableNow] = useState({ label: "" })
    const [eventHandler, setEventHandlers] = useState(0)
    // const [step, setStep] = useState(0);
    const navigate = useNavigate();
 


    const branchByBusines = async (business) => {
        console.log(type);
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficesByBusiness(business.id)
            const branchResult = response.data.data.filter(({ state }) => state === 1);
            setBranchoffices(branchResult)
            setEventHandlers(1)
        } catch (err) {
            console.log(err);
        }
    }

    //get parthners of the business
    const getParthners = async (token) => {
        
        let data = businessinfo
        try {
            const response = await ParthnerFetchAPI.getBusinessParthners(token);
            //setParthners(response.data.parthners);


            console.log(response.data.parthners);
            setParthners(response.data.parthners)



        } catch (err) {
            console.log(err);
        }
    }

    //save and select parthner
    const selectRestaurant = (data) => {
        if (localStorage.getItem('x-access-machine-token') !== undefined && localStorage.getItem('x-access-machine-token') !== '' && localStorage.getItem('x-access-machine-token') !== null) {
            validToken(0, false, businessinfo);
        } else {
            generateToken(0, false, businessinfo);
        }

        let jsonNewParthner = { id: data.parthner_branchoffice_id, name: data.parthner_name, code: data.code, branchoffice_open: null, branchoffice_printer: null, branchoffice_support_phone: data.phone }
        localStorage.setItem('branchoffice', JSON.stringify(jsonNewParthner))

        if (data.parthner_branch_machine_id !== null) {
            getInfoMachine(data.parthner_branch_machine_id, data)
        }

        localStorage.setItem('logo', data.logo)
    }

    //function get machine info
    const getInfoMachine = async (machine_id, dataBranchoffice) => {
        try {
            const response = await MachinesFetchAPI.getmachineByid(machine_id, token)
            let infoMachineJson = response.data.data
            console.log(response);

            let jsonNewMachine = {
                id: infoMachineJson.machine_per_branch_id,
                code: infoMachineJson.code,
                is_logged: 0,
                name_type_machine: infoMachineJson.name_type_machine,
                machine_type_id: infoMachineJson.machine_type_id,
                printers: infoMachineJson.printers,
                machine_config: infoMachineJson.machine_config
            }

            localStorage.setItem('machine', JSON.stringify(jsonNewMachine));
            localStorage.setItem('parthner_order', true);
            // navigate(`/kiosk/ecommers/stant/${business_code}/${dataBranchoffice.code}/${jsonNewMachine.code}`)

            navigate(`/home/${business_code}/${dataBranchoffice.code}/${jsonNewMachine.code}`)

        } catch (err) {
            console.log(err);
        }
    }



    const getBusinessByCode = async () => {
        try {
            const response = await BusinessFetchAPI.getBusinessByCode(business_code)
            localStorage.setItem('logo', response.data.businessConfig.logo);
            setLogo(response.data.businessConfig.logo)
            setBackground(response.data.businessConfig.background)
            branchByBusines(response.data.business);

            let dataBusiness = {
                "id": response.data.business.id,
                "name": response.data.business.name,
                "description": response.data.business.description,
                "code": response.data.business.code,
                "config": null
            }

            if (localStorage.getItem('client') !== '' && localStorage.getItem('client') !== undefined && localStorage.getItem('client') !== null) {
                setDataClient(JSON.parse(localStorage.getItem('client')))
            }

            if (localStorage.getItem('table') !== '' && localStorage.getItem('table') !== undefined && localStorage.getItem('table') !== null) {
                setDataTable(JSON.parse(localStorage.getItem('table')))
            }


            validToken(0, true, dataBusiness)
            setBusinessinfo(dataBusiness)

            localStorage.setItem('businessConfig', JSON.stringify(response.data.businessConfig))
            localStorage.setItem('business', JSON.stringify(dataBusiness))


        } catch (err) {
            console.log(err);
        }
    }

    const getBranchOfficeByCode = async (branchoffice_code, token) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code, token)
            console.log(response.data.branchoffice);
            let branchoffice_info = response.data.branchoffice
            let machine_ecommerce = response.data.branchoffice.machine_ecommerce
            let machine_table = response.data.branchoffice.machine_table
            let data_branchoffice = {
                "id": branchoffice_info.id,
                "name": branchoffice_info.name,
                "code": branchoffice_info.code,
                "order_printed": branchoffice_info.printed_order,
                "branchoffice_printer": branchoffice_info.printer_name,
                "branchoffice_support_phone": branchoffice_info.supportPhone,
                "branchoffice_open": branchoffice_info.branchoffice_open,
                'machine_ecommerce': machine_ecommerce,
                'machine_table': machine_table
            }


            if (machine_ecommerce !== null) {
                if (machine_ecommerce.length > 0) {
                    getMachineByCode(branchoffice_code, token, machine_ecommerce[0].code)
                } else {
                    ErrorAlert('', 'Esta sucursal no tiene eccomerce', 'warning')
                }
            } else {
                ErrorAlert('', 'Esta sucursal no tiene eccomerce', 'warning')
            }


            localStorage.setItem('branchoffice', JSON.stringify(data_branchoffice));
            localStorage.setItem('branchoffice_original', JSON.stringify(data_branchoffice));


        } catch (err) {
            console.log(err);
        }


    }


    const generateToken = async (branchoffice_code, stateModalLog, dataBusiness) => {


        let business_id = 0
        if (dataBusiness === undefined) {
            business_id = businessinfo.id;
        } else {
            business_id = dataBusiness.id
        }

        try {
            const response = await AuthFetchAPI.generatePublicToken(business_id);
            console.log(response.data.token);

            setToken(response.data.token)
            getParthners(response.data.token)
            getTablesByBusiness(business_id, response.data.token)
            if (stateModalLog !== true) {
                localStorage.setItem('x-access-machine-token', response.data.token)
                getBranchOfficeByCode(branchoffice_code, response.data.token)
            } else if (stateModalLog !== false) {
                localStorage.setItem('x-access-machine-token', response.data.token)
            }

            //authMachine(branchoffice_code)
        } catch (err) {
            console.log(err);
        }
    }

    const validToken = async (branchoffice_code, stateModalLog, dataBusiness) => {
        console.log("hola");
        console.log(dataBusiness);
        try {
            const response = await AuthFetchAPI.validToken(localStorage.getItem('x-access-machine-token'));
            if (response.data === true) {

                setToken(localStorage.getItem('x-access-machine-token'));
                getParthners(localStorage.getItem('x-access-machine-token'))
                getTablesByBusiness(dataBusiness.id, localStorage.getItem('x-access-machine-token'))
                if (stateModalLog !== true) {
                    getBranchOfficeByCode(branchoffice_code, localStorage.getItem('x-access-machine-token'))
                }
            } else {
                generateToken(branchoffice_code, stateModalLog, dataBusiness)
            }
        } catch (err) {
            console.log(err);
            generateToken(branchoffice_code, stateModalLog, dataBusiness)
        }
    }



    const getMachineByCode = async (branchoffice_code, token, machine_code) => {
        try {
            const response = await MachinesFetchAPI.getMachineByCode(machine_code, branchoffice_code, token);
            let machines_info = response.data.machine
            console.log(response.data.machine);
            let data = {
                "id": machines_info.id,
                "code": machines_info.code,
                "is_logged": machines_info.is_logged,
                "name_type_machine": machines_info.name_type_machine,
                "machine_type_id": machines_info.machine_type_id,
                "printers": [],
                "machine_config": {
                    "mainContainer": true,
                    "deliveryGroup": {
                        "actvPlus": true
                    },
                    "buttons": {
                        "groupPlus": true,
                        "groupLite": false,
                        "menu": {
                            "groupPlus": true,
                            "topNav": false,
                            "actvLite": false
                        },
                        "discounts": {
                            "actvPlus": true,
                            "actvLite": false
                        },
                        "coupons": {
                            "actvPlus": true,
                            "actvLite": false
                        },
                        "pendingOrders": {
                            "actvPlus": true,
                            "actvLite": false
                        },
                        "return": {
                            "actvPlus": true,
                            "actvLite": false
                        }
                    }
                },
                "machine_open": machines_info.machine_open
            }

            localStorage.setItem('machine', JSON.stringify(data));
            localStorage.setItem('parthner_order', false);
            navigate(`/home/${business_code}/${branchoffice_code}/${machine_code}`)
            // navigate(`/kiosk/ecommers/stant/${business_code}/${branchoffice_code}/${machine_code}`)
        } catch (err) {
            console.log(err);
        }
    }

    const getTablesByBusiness = async (businessinfo_id, token) => {
        console.log(tableCode);
        if (tableCode != undefined) {
            try {
                const response = await TablesFetchAPI.getBusinessTables(businessinfo_id, token);
                console.log("MESAS");
                console.log(response.data.tables);

                let tablesInfo = []
                response.data.tables.forEach(table => {
                    if (table.status === 1) { tablesInfo.push({ value: table, label: table.name }) }
                });


                //ROOOM SERVICE
                let filterTable = tablesInfo.find((table) => table.value.table_code === tableCode)
                let codeRoom = localStorage.getItem('codeRoom')



                if (filterTable != undefined) {
                    setTableNow(filterTable)
                    console.log(filterTable);
                    if (codeRoom != undefined || codeRoom != '' || codeRoom != null) {
                        if (codeRoom == filterTable.value.password) {
                            setShowModalWelcomePage(true)
                            localStorage.setItem('table', JSON.stringify(filterTable.value))
                        } else {
                            setShowModalWelcomePage(false)
                            setModalStand(true)
                        }
                    } else {
                        setShowModalWelcomePage(false)
                        setModalStand(true)
                    }

                }
                //----------------------------------------------
                setTables(tablesInfo);
            } catch (err) {
                console.log(err);
            }
        } else {
            console.log("no hay mesa");
        }
    }


    const getClientsByBusiness = async () => {

        try {

            const response = await ClientsFetchAPI.getClientsByBusiness(businessinfo.id, token)
            console.log(response.data.data);
            setClients(response.data.data)
        } catch (err) {
            console.log(err);
        }
    }





    const handleModalSesionValid = (typeUser) => {

        if (typeUser === 'stan') {
            if (dataTable.name === 'Soy un stan') {
                setShowModal(true)

                setTypeUser(typeUser)
                getTablesByBusiness()
            } else {
                setShowModalDetail(true)
            }
        } else if (typeUser === 'client') {
            if (dataClient.client_name === 'Soy usuario') {
                setShowModal(true)

                setTypeUser(typeUser)
                getClientsByBusiness()
            } else {
                setShowModalDetail(true)
            }
        }

    }


    const handleSaveBranchoffice = (branchoffice) => {
        let data = {
            "id": branchoffice.branchofficeId,
            "name": branchoffice.branchofficeName,
            "code": branchoffice.branchoffice_code,
            "order_printed": 0,
            "branchoffice_printer": null,
            "branchoffice_support_phone": "",
            "branchoffice_open": 1
        };



        localStorage.setItem('branchoffice', JSON.stringify(data));
        if (localStorage.getItem('x-access-machine-token') !== undefined && localStorage.getItem('x-access-machine-token') !== '' && localStorage.getItem('x-access-machine-token') !== null) {
            validToken(branchoffice.branchoffice_code, businessinfo);
        } else {
            generateToken(branchoffice.branchoffice_code, businessinfo);
        }
    }

    const clearParamslocal = () => {
        localStorage.setItem('x-access-machine-token', '')
        localStorage.setItem('branchoffice', '')
        localStorage.setItem('machine', '')
        localStorage.setItem('business', '')
        localStorage.setItem('logo', '')
        localStorage.setItem('parthner_order', '')
        localStorage.setItem('selectedCategorie', '')
        localStorage.setItem('shoppingCart', '')
    }


    useEffect(() => {
        clearParamslocal()
        getBusinessByCode();
        setPageLoader(0)


    }, [pageLoader, setPageLoader])

    useEffect(() => {
        setEventHandlers(0)
    }, [eventHandler])

    return (
        <>
            <section className='ecomerce-container-0'>
                <div className='ecomerce-container-1 position-realtive mx-2 pb-2'>
                    <Header type={'restaurant'}></Header>
                    {/* <------  ----->   */}
                    <h4 className='text-white fw-bold text-center '>Restaurantes </h4>
                    <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0'>






                        {/* <------  ----->  */}

                        {parthners.length > 0 ? <>
                            {parthners.map((parthner) => (
                                <>
                                    {/*<div class="ecommerce-branchs-card d-flex align-items-center bg-white rounded-3 mt-1 shadow-sm mx-1 p-1"
                                        onClick={() => selectRestaurant(parthner)}
                                    >
                                        <div class="flex-shrink-0">
                                            <img src={parthner.logo}
                                                className='img-ecommerce-branchs-card rounded-3 border' />
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <div className='mt-2 me-2'>
                                                <span class="badge bg-primary-subtle text-primary">
                                                    <i class="uil uil-store"></i>{parthner.parthner_name}
                                                </span>
                                                <span className='mt-1 d-block value-ecommerce-branchs-card'> {parthner.branchoffice_name} </span>
                                            </div>
                                        </div>
                                    </div>*/}

                                    {/* Restaurantes  */}
                                    <div className='ecomerce-container-section-0  border rounded-4 bg-white p-2' onClick={() => selectRestaurant(parthner)}>
                                        {/* Card */}
                                        <label className="card-restaurant">

                                            <span class="badge badge-open rounded-2  position-absolute badge-position">Abierto</span>



                                            <input name="plan" className="radio" type="radio" />

                                            <span className="plan-details">

                                                <div className="d-flex justify-content-start">
                                                    <img src={parthner.logo}
                                                        alt="Restaurant" className="rounded-2  border img-restaurant " />
                                                </div>
                                                <span className="plan-type">{parthner.branchoffice_name} </span>
                                                <p className="plan-description">{parthner.parthner_name}</p>

                                                <div className="d-flex gap-2">
                                                    <span class="badge bg-light text-secondary">Restaurant </span>
                                                    {/*<span class="badge bg-light text-secondary">Burger</span>

                                                    <span class="badge bg-light text-secondary">Pizza</span>*/}
                                                </div>
                                            </span>
                                        </label>

                                    </div>
                                </>
                            ))}</> : <></>}
                        {
                            !branchoffices.length
                                ? <>
                                    <div className='d-flex justify-content-center align-items-center border
                                             p-4 mt-5 bg-blur-sp-2 rounded-4'>
                                        <h6 className='fw-bold text-dark text-center p-4'>
                                            No hay Tiendas disponibles
                                        </h6>
                                    </div>
                                </>
                                : <>
                                    {branchoffices.map((branchoffice) => (
                                        <>
                                            {/*<div class="ecommerce-branchs-card d-flex align-items-center bg-white rounded-3 mt-1 shadow-sm mx-1 p-1"
                                                onClick={() => handleSaveBranchoffice(branchoffice)}
                                            >
                                                <div class="flex-shrink-0">
                                                    <img src={logo}
                                                        className='img-ecommerce-branchs-card rounded-3 border' />
                                                </div>
                                                <div class="flex-grow-1 ms-3">
                                                    <div className='mt-2 me-2'>
                                                        <span class="badge bg-primary-subtle text-primary">
                                                            <i class="uil uil-store"></i>{branchoffice.businessName}
                                                        </span>
                                                        <span className='mt-1 d-block value-ecommerce-branchs-card'> {branchoffice.branchofficeName} </span>
                                                    </div>
                                                </div>
                                            </div>*/}

                                            <div className='ecomerce-container-section-0  border rounded-4 bg-white p-2' onClick={() => handleSaveBranchoffice(branchoffice)}>
                                                {/* Card */}
                                                <label className="card-restaurant">

                                                    <span class="badge badge-open rounded-2  position-absolute badge-position">Abierto</span>



                                                    <input name="plan" className="radio" type="radio" />

                                                    <span className="plan-details">

                                                        <div className="d-flex justify-content-start">
                                                            <img src={logo}
                                                                alt="Restaurant" className="rounded-2  border img-restaurant " />
                                                        </div>
                                                        <span className="plan-type">{branchoffice.branchofficeName}</span>
                                                        <p className="plan-description">{branchoffice.businessName}</p>

                                                        <div className="d-flex gap-2">
                                                            <span class="badge bg-light text-secondary">Restaurant </span>
                                                            {/*<span class="badge bg-light text-secondary">Burger</span>

                                                    <span class="badge bg-light text-secondary">Pizza</span>*/}
                                                        </div>
                                                    </span>
                                                </label>

                                            </div>
                                        </>
                                    ))}
                                </>
                        }
                    </div>
                    {/* <BarMenu
                        bussinesCode={business_code}
                        // branchofficeCode={branchoffice_code}
                        // machineCodeTable={machine_code_table} 
                        /> */}
                </div>
            </section>

{type == "true" ? <></> : <>
            <ModalWelcomePage showModalWelcomePage={showModalWelcomePage} setShowModalWelcomePage={setShowModalWelcomePage} background={background} tableNow={tableNow} handleSaveBranchoffice={handleSaveBranchoffice} branchoffices={branchoffices}></ModalWelcomePage></>}
            <LoginStand modalStand={modalStand} setModalStand={setModalStand} tableNow={tableNow} background={background} handleSaveBranchoffice={handleSaveBranchoffice} branchoffices={branchoffices} />
        </>
    )
}

export default BranchofficeSelect
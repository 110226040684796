import React, { useState } from "react";
import "./restaurant.css";

const RestaurantModal = ({brachoffice_name}) => {

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    return (
        <>
            <div>
                <button  className="btn-restaurant rounded-4">
                    <div className="d-flex align-items-center align-content-center">
                        <div className="flex-shrink-0">
                            <i className="uil uil-location-point btn-restaurant-icon"></i>
                        </div>
                        <div className="flex-grow-1">
                            <div className="d-flex align-items-start">
                                <span className="opacity-50 btn-restaurant-subtitle">Restaurante </span>
                            </div>

                            <h2 className=" fw-bold btn-restaurant-title">{brachoffice_name} </h2>
                        </div>
                    </div>
                </button>

                {isOpen && (
                    <div className="modal-overlay-ecom">
                        <div className="modal-content-ecom">
                            <div className="d-flex justify-content-between align-items-center  p-0 mt-2 mx-2 ">
                                <h2 className="fw-semibold h4"><i class="uil uil-location-point"></i> Restaurantes</h2>

                                <button onClick={closeModal} type="button"
                                    className="p-3 btn-close bg-secondary bg-opacity-10 rounded-pill small text-black "></button>
                            </div>

                            <section className=' modal-content-ecom-restaurant '>
                                <div className='ecomerce-container-1 position-realtive mx-2 pb-2 mt-4'>
                                    {/* Restaurantes  */}
                                    <div className='ecomerce-container-section-0  border rounded-4 bg-white p-2'>
                                        {/* Card */}
                                        <label className="card-restaurant">

                                            <span class="badge badge-open rounded-2  position-absolute badge-position">Abierto</span>



                                            <input name="plan" className="radio" type="radio" />

                                            <span className="plan-details">

                                                <div className="d-flex justify-content-start">
                                                    <img src="https://plus.unsplash.com/premium_photo-1661953124283-76d0a8436b87?q=80&w=2088&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                        alt="Restaurant" className="rounded-2  border img-restaurant " />
                                                </div>
                                                <span className="plan-type">Restaurant Name </span>
                                                <p className="plan-description">
                                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam suscipit nostrum neque autem.
                                                </p>

                                                <div className="d-flex gap-2">
                                                    <span class="badge bg-light text-secondary">Restaurant </span>
                                                    <span class="badge bg-light text-secondary">Burger</span>

                                                    <span class="badge bg-light text-secondary">Pizza</span>
                                                </div>
                                            </span>
                                        </label>

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </div>

        </>
    )
}

export default RestaurantModal